import React, { forwardRef, useEffect, useState  } from "react";
import { useRouter } from "next/router";
import styles from "./cdrPopup.module.css";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import clsx from "clsx";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "next-i18next";
import { DialogContent, DialogActions, Grow, makeStyles } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import KirdModal from "components/common/KirdModal";
import { useUser } from "shared/api/useUser";
import { selectCodeList } from "shared/utils/CommonCode";
import { FormikErrorSnackbar } from "shared/utils/FormikErrorSnackbar";
import { MemberFormType } from "pages/smedu/member/create";
import CdrStepPopup from "./cdrStepPopup";

// 회원 정보 조회
const GET_MEMBER_BY_ID = gql`
  query GetMemberById($id: ID!) {
    member: getMemberById(id: $id) {
      id
      name
      jobField
      careerLevel
      createdDateTime
    }
  }
`;

// 회원 직무 및 경력단계 수정
const EDIT_MEMBER = gql`
  mutation EditMemberCareerLevel($request: EditMemberCareerLevelInput!) {
    member: editMemberCareerLevel(request: $request) {
      id
    }
  }
`;

// CSS
const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  formikText: {
    "& input, .MuiOutlinedInput-input": {
      paddingTop: 15.5,
      paddingBottom: 15.5,
    },
    "&, & > div": {
      borderRadius: 5,
    },
  },
  formikSelect: {
    width: 250,
    "& .MuiOutlinedInput-input": {
      paddingTop: 15.5,
      paddingBottom: 15.5,
    },
  },
  nonSelect: {
    color: theme.palette.grey.A200,
  },
  placeholder: {
    color: theme.palette.grey.A200,
  }
}));

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Grow in={true} ref={ref} {...props} timeout={{ appear: 0, enter: 500, exit: 200 }} />;
});

interface Props {
  open: boolean;
  onClose: Function;
  fullWidth?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  openPopupCookieName?: string;
}

interface CodeType {
  id: number;
  code: string;
  group: string;
  name: string;
}

// 내정보화면 유저 타입
interface MemberEditFormType extends MemberFormType {
  type: string;
  birthday: string;
}

export default function cdrSettingPopup(props: Props) {
  const { t } = useTranslation();
  const { open, onClose, openPopupCookieName, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();

  // 쿠키 불러오기
  const cookies = parseCookies();
  const openPopupCookie = cookies[openPopupCookieName];

  // query, user
  const { push, query, isReady } = useRouter();
  const user = useUser();
  
  const [selectOn1, setSelectOn1] = useState(false);
  const [selectOn2, setSelectOn2] = useState(false);

  // 경력단계 설명 팝업
  const [openCareerLevel, setOpenCareerLevel] = useState(false);

  // 직무분야 목록
  const [jobFieldList, setJobFieldList] = useState<Array<CodeType>>([]);

  // 경력단계
  const [careerLevelList, setCareerLevelList] = useState<Array<CodeType>>([]);

  // 경력단계 팝업 스킵 쿠키 상태
  // 렌더링을 위한 상태 변수
  const [CdrStepPopupSkip, setCdrStepPopupSkip] = useState(false);

  // 회원 정보
  const [member, setMember] = useState<MemberEditFormType>();

  // 회원 정보 조회
  const [fnGetMember] = useLazyQuery(GET_MEMBER_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.member) {
        setMember(data.member);

        formik.setFieldValue("id", data.member?.id);
//         회원의 기존 정보와 관계없이 선택으로 나오게 하기 위해서 주석처리
//         formik.setFieldValue("jobField", data.member?.jobField);
//         formik.setFieldValue("careerLevel", data.member?.careerLevel);
      }
    },
  });

  // 회원 정보 수정
  const [fnEditMember] = useMutation(EDIT_MEMBER, {
    onCompleted: () => {
      enqueueSnackbar("회원 정보 수정에 성공하였습니다.", {
        variant: "success",
      });
      fnGetMember({ variables: { id: user.data?.user?.id } });
    },
    onError: () => {
      enqueueSnackbar("회원 정보 수정에 실패하였습니다.", { variant: "error" });
    },
  });

  const validationSchema = yup.object({
    jobField: yup.string().required("직무분야를 입력해주세요"),
    careerLevel: yup.string().required("경력단계를 입력해주세요"),
  });

  const formik = useFormik({
    initialValues: {
      id: member?.id,
      jobField: member?.jobField,
      careerLevel: member?.careerLevel,
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      let request: any = {
        id: values.id,
        jobField: values.jobField,
        careerLevel: values.careerLevel
      };

      // 수정 로직
      let isSave = true;

      if (isSave) {
        // 회원 정보 수정
        fnEditMember({ variables: { request: { ...request } } });
      }
    },
  });
  FormikErrorSnackbar(formik);

  const fnSave = () => {
    /* 유효성 검사를 통과한다면 check에 true */
    /* 일단 테스트용으로 check에 true 대입 */
    /* 유효성검사가 validationSchema에 있으므로 check 제거 */
    formik.handleSubmit();
  }

  const fnSaveMove = () => {
    formik.handleSubmit();
    if(formik.isValid){
      destroyCookie(null, openPopupCookieName); // 팝업 오픈 플래그 쿠키 삭제
      onClose(); // 팝업창 닫기
      push("/capacityDev"); // 이동
      /* push를 사용하니 쿠키에 따라 state의 값을 정하는 부분이 동작되지 않는 문제가 발생하여 window.location.href로 변경 */
      //window.location.href = "/capacityDev";
    }
  }

  const fnSkip = () => {
    /* 쿠키 추가 */
    setCookie(null, "careerLevelSettingSkip", "1", {
      maxAge: 24*60*60, // 24시간, 60분, 60초
      path: "/"
    })
    setCdrStepPopupSkip(true);
    onClose();
  }

  useEffect(() => {
    if (isReady) {
      setJobFieldList(selectCodeList("6105"));
      setCareerLevelList(selectCodeList("6109"));
      if (user.data?.user?.id) {
        fnGetMember({ variables: { id: user.data?.user?.id } });
      }
    }
  }, [query, user]);

  return (
    <KirdModal title={t("직무 및 경력단계 확인")} maxWidth="xl" open={open} onClose={onClose} transitionComponent={Transition} {...other}>
      <DialogContent className={styles.popContentWrap}>
        <div className={styles.cdrPop}>
          <div className={styles.cdrStepCont}>
            <div className={styles.cdrGuideMsg}>
              <p className={styles.txtDescription02}>아래 직무 및 경력 단계를 확인하신 후 해당하는 항목을 선택 해 주세요.<br/>선택하신 내용에 따라 역량 개발 로드맵을 제공해 드리며, 체계적인 학습을 지원해 드립니다.</p>
            </div>
            <div className={styles.myCdrSelect}>
              <fieldset className={styles.myCdrForm}>
                <legend className={styles.ir}>나의 경력단계, 직무 선택</legend>
                <ul className={styles.mcSlelectList}>
                  <li>
                    <strong className={styles.titMscs}>나의 직무</strong>
                    <div className={clsx(styles.selectBox, {[styles.on]: selectOn1})}>
                      <select
                        name="jobField"
                        onClick={() => setSelectOn1(!selectOn1)}
                        onBlur={() => setSelectOn1(false)}
                        onChange={formik.handleChange}
                        value={formik.values.jobField || ""}
                      >
                        <option value="" className={styles.placeholder}>
                          선택
                        </option>
                        {jobFieldList?.map((jobField: CodeType) => (
                          <option key={jobField?.id} value={jobField?.code}>
                            {jobField?.name}
                          </option>
                        ))}
                      </select>
                      <i className={styles.icon_arrow}></i>
                    </div>
                  </li>
                  <li>
                    <strong className={styles.titMscsBtn}>
                      나의 경력단계
                      <button type="button" className={styles.btnIcon_description} onClick={() => setOpenCareerLevel(true)}>
                        <span className={styles.ir}>설명보기</span>
                      </button>
                    </strong>
                    <div className={clsx(styles.selectBox, {[styles.on]: selectOn2})}>
                      <select
                        name="careerLevel"
                        onClick={() => setSelectOn2(!selectOn1)}
                        onBlur={() => setSelectOn2(false)}
                        onChange={formik.handleChange}
                        value={formik.values.careerLevel || ""}
                      >
                        <option value="" className={styles.placeholder}>
                          선택
                        </option>
                        {careerLevelList?.map((careerLevel: CodeType) => (
                          careerLevel.name!="기타" && (
                            <option key={careerLevel.id} value={careerLevel.code}>
                              {careerLevel.name}
                            </option>
                          )
                        ))}
                      </select>
                      <i className={styles.icon_arrow}></i>
                    </div>
                  </li>
                </ul>
                <button type="button" className={styles.btnBlue} onClick={fnSave}>내 정보에 저장하기</button>
              </fieldset>
              <CdrStepPopup
                fullWidth={true}
                maxWidth='lg'
                open={openCareerLevel}
                onClose={() => setOpenCareerLevel(false)}
                myCareerLevel={member?.careerLevel && member.careerLevel}
                myJobField={member?.jobField && member.jobField}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={styles.cdrPop}>
          <div className={clsx(styles.bottomBtnWrap, styles.directionColum)}>
            <button type="button" className={styles.btnBlue} onClick={fnSaveMove}>내 정보 저장 및 역량개발 로드맵 확인</button>
            <button type="button" className={styles.btnSkip} onClick={fnSkip}>
              <span style={{textDecoration: "underline"}}>다음에 선택하기</span>
              <span style={{fontSize: "14px"}}>(내 정보에서 수정 가능합니다)</span>
            </button>
          </div>
        </div>
      </DialogActions>
    </KirdModal>
  );
}